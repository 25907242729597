@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-size: 100%;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
}

h1, h2, h3, h4, h5, p, ul, li {
  margin: 0;
  padding: 0;
  color: #05112A;
}

h1 {
  font-size: 1.5rem;
  font-weight: 400;
}

h2 {
  font-size: 1.25rem;
  font-weight: 600;
}

h3 {
  color: #3E4C59;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

h4 {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5;
}
h4[role=button] {
  cursor: pointer;
}

h5 {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.2;
}

h6 {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.2;
  margin: 1rem 0 0 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #333;
}

a {
  text-decoration: none;
  color: #007FA3;
}

#pe-icons-sprite {
  display: none;
}

svg {
  fill: currentColor;
}

.icon-18 {
  width: 18px;
  height: 18px;
}

.icon-24 {
  width: 24px;
  height: 24px;
}

.main {
  display: flex;
  max-width: 100%;
  align-items: stretch;
}

.wrapper {
  max-width: 80rem;
  padding: 0 4.5rem;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .wrapper {
    padding: 0 1.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .wrapper {
    padding: 0 1rem;
  }
}

/* Input ----*/
.gr-input {
  display: block;
  margin: 4px 0;
  padding: 0 12px;
  height: 36px;
  color: #252525;
  border: 1px solid #9AA5B1;
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  max-width: 100%;
}
.gr-input:focus {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
}
.gr-input:disabled {
  color: #C7C7C7;
  background-color: #E9E9E9;
}
.gr-input.search-input {
  border-radius: 99px;
  background: url(../images/icon_search.png) no-repeat 0.625rem center;
  padding-left: 2.5rem;
  font-size: 1rem;
  min-width: 19rem;
}

.sr-only {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

/* Checkbox ---*/
.gr-checkbox {
  position: relative;
}
.gr-checkbox input[type=checkbox] {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.gr-checkbox input[type=checkbox] ~ label {
  display: inline-block;
  line-height: 1.5;
  min-height: 24px;
  padding-left: 2.5em;
  position: relative;
  z-index: 2;
  cursor: pointer;
  font-size: 14px;
}
.gr-checkbox input[type=checkbox] ~ span {
  height: 22px;
  line-height: 1.5;
  text-align: center;
  width: 22px;
  border: 2px solid #c7c7c7;
  background: white;
  border-radius: 2px;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
}
.gr-checkbox input[type=checkbox] ~ span svg {
  height: 24px;
  opacity: 0;
  width: 24px;
  top: -3px;
  position: relative;
  left: -3px;
  fill: #047A9C;
}
.gr-checkbox input[type=checkbox]:disabled ~ span {
  height: 22px;
  line-height: 1.5;
  text-align: center;
  width: 22px;
  border: 2px solid #c7c7c7;
  border-radius: 2px;
  left: 0;
  position: absolute;
  top: 0;
  z-index: 0;
  background-color: #E9E9E9;
}
.gr-checkbox input[type=checkbox]:disabled ~ span svg {
  fill: #C7C7C7;
}
.gr-checkbox input[type=checkbox]:focus ~ span {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
}
.gr-checkbox input[type=checkbox]:checked ~ span svg {
  opacity: 1;
}

/* Breadcrumbs -----*/
.breadcrumnbs {
  display: flex;
  gap: 1.125rem;
  margin: 1rem 0 1.5rem;
}
.breadcrumnbs a {
  color: #3E4C59;
  text-decoration: none;
}
.breadcrumnbs a::after {
  content: "";
  width: 8px;
  height: 14px;
  display: inline-block;
  background: url(../images/icon_breadcrumb.png) no-repeat center center;
  margin-left: 1.125rem;
}
.breadcrumnbs a:last-child {
  color: #007FA3;
}
.breadcrumnbs a:last-child::after {
  display: none;
}
.breadcrumnbs a:hover {
  color: #007FA3;
}

.badge {
  display: inline-block;
  background: #CFD8DC;
  border-radius: 99px;
  font-weight: 400;
  font-size: 0.75rem;
  color: #263238;
  padding: 2px 6px;
  margin-left: 0.5rem;
}

.gr-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #6A7070;
  background: transparent;
  color: #6A7070;
  padding: 8px 24px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 40px;
  position: relative;
  margin: 12px;
  line-height: 1.15;
  text-decoration: none;
  white-space: nowrap;
}
.gr-btn:hover {
  color: #252525;
  border: 1px solid #252525;
}
.gr-btn:focus {
  outline: 1px solid #6A7070;
  box-shadow: none;
}
.gr-btn.green {
  background: #007E53;
  border: 1px solid transparent; /* #AE367E */
  color: white;
}
.gr-btn.green:hover, .gr-btn.green:focus {
  background: #006B49;
}
.gr-btn.primary {
  background: linear-gradient(73deg, #4858D4 0.7%, #009184 116.15%);
  border: 1px solid transparent; /* #AE367E */
  color: white;
  min-height: 40px;
}
.gr-btn.primary:hover {
  color: white;
  background: linear-gradient(85deg, #4a2c9b 0%, #01776d 100%);
}
.gr-btn.primary:focus {
  outline: 1px dashed #eee;
  outline-offset: -4px;
  box-shadow: none;
}
.gr-btn.primary:disabled {
  opacity: 0.5;
  color: white !important;
}
.gr-btn.attention {
  background-color: #252525;
  border: 1px solid #252525;
  color: #fff;
}
.gr-btn.attention:hover {
  background-color: #000000;
}
.gr-btn.attention:focus {
  box-shadow: none;
  outline: 1px dashed #eee;
  outline-offset: -4px;
}
.gr-btn.small {
  min-width: 128px;
  padding: 7px 20px;
  font-size: 14px;
}
.gr-btn.small:focus:after {
  padding: 18px 21px;
}
.gr-btn.extra-large {
  min-width: 152px;
  padding: 10px 24px;
  font-size: 16px;
}
.gr-btn.extra-large:focus:after {
  padding: 22px 21px;
  border-radius: 24px;
}
.gr-btn:disabled {
  background-color: #E9E9E9;
  color: #6A7070 !important;
  border: 0 !important;
  cursor: default !important;
}
.gr-btn.icon-btn-24 {
  min-width: auto;
  padding: 0.5rem 0.75rem;
  border: 0;
  margin: 0;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}
.gr-btn.icon-btn-24:focus {
  border-radius: 50%;
  box-shadow: none;
  background-color: rgba(4, 122, 156, 0.08);
}
.gr-btn.icon-btn-24:hover {
  border-radius: 50%;
  box-shadow: none;
  background-color: #f2f2f2;
}
.gr-btn.icon-btn-24:active, .gr-btn.icon-btn-24 .active {
  border-radius: 50%;
  box-shadow: none;
  background-color: rgba(4, 122, 156, 0.08);
}
.gr-btn.icon-btn-24 svg {
  fill: #6A7070;
}
.gr-btn.icon-btn-24:disabled {
  background-color: transparent !important;
}
.gr-btn.icon-btn-24:disabled svg {
  fill: #ccc;
}
.gr-btn.icon-btn-18 {
  min-width: auto;
  padding: 0.5rem 0.75rem;
  border: 0;
  margin: 0;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
}
.gr-btn.icon-btn-18:focus {
  border-radius: 50%;
  box-shadow: none;
  background-color: rgba(4, 122, 156, 0.08);
}
.gr-btn.icon-btn-18:hover {
  border-radius: 50%;
  box-shadow: none;
  background-color: #f2f2f2;
}
.gr-btn.icon-btn-18:active, .gr-btn.icon-btn-18 .active {
  border-radius: 50%;
  box-shadow: none;
  background-color: rgba(4, 122, 156, 0.08);
}
.gr-btn.icon-btn-18:disabled {
  background-color: transparent !important;
}
.gr-btn.icon-btn-18:disabled svg {
  fill: #ccc;
}
.gr-btn.icon-btn-18 svg {
  fill: #6A7070;
}
.gr-btn.next-btn {
  background: linear-gradient(86.65deg, #1CE6B5 -81.59%, #2128D9 100%);
  color: #fff;
  opacity: 0.8;
}
.gr-btn.next-btn svg {
  fill: currentColor;
}
.gr-btn.next-btn:hover, .gr-btn.next-btn:focus {
  opacity: 1;
}
.gr-btn.no-border {
  border: 0;
}
.gr-btn.no-border:hover {
  border: 0;
}
.gr-btn.no-border:focus:after {
  border-radius: 5px;
  min-width: auto;
  left: auto;
  top: auto;
  padding: 16px;
}
.gr-btn.link-btn {
  border: 0;
  padding: 0;
  min-width: 0;
  color: #05112A;
}
.gr-btn.link-btn:focus, .gr-btn.link-btn:active {
  box-shadow: none;
  outline: none;
}

.gr-radio {
  margin-bottom: 1.25rem;
  min-height: 1rem;
  position: relative;
  display: flex;
  align-items: center;
}
.gr-radio input[type=radio] {
  opacity: 0;
  position: absolute;
}
.gr-radio input[type=radio]:focus ~ span {
  outline: 0;
}
.gr-radio input[type=radio] + label {
  display: inline-block;
  vertical-align: middle;
  line-height: 18px;
  padding-left: 36px;
  font-size: 1rem;
  cursor: pointer;
}
.gr-radio input[type=radio] ~ span {
  -webkit-box-sizing: content-box;
  border: 2px solid #c7c7c7;
  background: white;
  border-radius: 50%;
  box-sizing: border-box;
  color: #6a7070;
  display: block;
  height: 1.5rem;
  left: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 1.5rem;
}
.gr-radio input[type=radio] ~ span svg {
  opacity: 0;
}
.gr-radio input[type=radio]:checked ~ span {
  border-color: transparent;
}
.gr-radio input[type=radio]:checked ~ span svg.select-icon {
  opacity: 1;
  top: -8px;
  position: relative;
  left: 0px;
  fill: #047A9C;
}
.gr-radio input[type=radio]:disabled ~ span {
  border-color: #ddd;
  background-color: #f7f7f7;
}
.gr-radio input[type=checkbox] {
  opacity: 0;
  position: absolute;
  margin: 0 0 0 3px;
}
.gr-radio input[type=checkbox] + label {
  display: inline-flex;
  align-items: center;
  line-height: 18px;
  padding-left: 40px;
  font-size: 1rem;
  cursor: pointer;
}
.gr-radio input[type=checkbox] ~ span {
  -webkit-box-sizing: content-box;
  border: 2px solid #7E7F80;
  background: white;
  border-radius: 2px;
  box-sizing: content-box;
  color: #6a7070;
  display: block;
  height: 20px;
  width: 20px;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: -1;
}
.gr-radio input[type=checkbox] ~ span svg {
  height: 24px;
  opacity: 0;
  width: 24px;
  position: absolute;
  left: -2px;
  top: -2px;
}
.gr-radio input[type=checkbox]:focus ~ span {
  outline: 0;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #1977D4;
}
.gr-radio input[type=checkbox]:checked ~ span svg.select-icon {
  opacity: 1;
  fill: #047A9C;
}
@media only screen and (max-width: 480px) {
  .gr-radio input[type=checkbox]:checked ~ span svg.select-icon {
    top: -3px;
  }
}
.gr-radio input[type=checkbox]:disabled ~ span {
  border-color: #ddd;
  background-color: #f7f7f7;
}
.gr-radio.selected input[type=radio]:checked ~ span svg.correct-icon, .gr-radio.selected input[type=checkbox]:checked ~ span svg.correct-icon {
  opacity: 1;
  top: -8px;
  position: relative;
  left: -1px;
  fill: #038238;
}
.gr-radio.selected input[type=radio]:checked ~ span svg.select-icon, .gr-radio.selected input[type=checkbox]:checked ~ span svg.select-icon {
  display: none;
}

/* Old Header styles -----
.global-header {
    height: 4rem;
    position: relative;
    padding: 0.975rem 0;

    > .wrapper {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        justify-content: space-between;
    }

    .logo {
        width: 126px;
        height: 32px;
    }

    .meta{
        font-size: 0.875rem;
        line-height: 1.5;
        color: #505759;
        display: inline-flex;
        align-items: center;
        margin-right: 1rem;

        @include devices(large) {
            margin-right: 0;
        }

        .gr-btn {
            margin-left: 0.5rem;

            @include devices(medium) {
                display: none;
            }
        }

        .user-profile {
            font-weight: 700;
            display: flex;
            align-items: center;
            margin-right: 1rem;

            @include devices(medium) {
                display: none;
                margin-right: 0;
            }

            &:after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 8px 6px 0 6px;
                border-color: #252525 transparent transparent transparent;
                margin-left: 0.5rem;
            }        
        }

        .user-profile--mobile {
            display: none;

            span {
                background-color: #00655B;
                color: #fff;
                display: flex;
                text-transform: uppercase;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
            }

            @include devices(medium) {
                display: flex;
            }
        }
    }

    &.wide {
        .wrapper {
            max-width: 100%;
            padding: 0 1rem 0 2rem;
        }
    }
} */
header {
  grid-row: 1;
  grid-column: 1/span 12;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  justify-content: space-between;
  position: relative;
  max-height: 70px;
  background: var(--neutral-white-white-70, rgba(255, 255, 255, 0.7));
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  z-index: 12;
}
header.overview {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}
header.overview + main {
  padding-top: 70px;
}
header .left, header .right {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
header .right label {
  font-size: 0.75rem;
  line-height: 1.5;
}
header .left {
  gap: 1.5rem;
}
header h1 {
  font-size: 20px;
  color: #020917;
  font-weight: 600;
  font-family: "Open Sans", Helvetica, sans-serif;
  display: flex;
  align-items: center;
  gap: 0.625rem;
}
header .MuiSvgIcon-root {
  fill: #585858;
}
header .badge {
  width: 16px;
  height: 16px;
  border-radius: 85px;
  background-color: #C117BA;
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  font-size: 10px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  font-weight: 600;
}
header.show {
  animation: headerShow 0.3s ease-in-out;
  animation-fill-mode: forwards;
}
header.hide {
  animation: headerHide 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes headerHide {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100px);
  }
}
@keyframes headerShow {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0);
  }
}
.overview-hero {
  padding: 2.5rem 0;
  min-height: 16rem;
  z-index: 2;
}
@media only screen and (max-width: 480px) {
  .overview-hero {
    padding: 1rem 0;
  }
}
@media only screen and (max-width: 480px) {
  .overview-hero.pinned {
    min-height: 0;
  }
}
@media only screen and (max-width: 480px) {
  .overview-hero.pinned .meta-data, .overview-hero.pinned .overview-hero__right, .overview-hero.pinned .progress-indicator {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .overview-hero.pinned .action-bar {
    grid-row: 2;
  }
}
.overview-hero .gradient {
  background: linear-gradient(80.83deg, rgba(232, 203, 192, 0.1) 33.76%, rgba(99, 111, 164, 0) 93.73%), linear-gradient(87.2deg, rgba(255, 255, 255, 0.9) 19.77%, rgba(255, 255, 255, 0) 99.48%), rgba(4, 122, 156, 0.4);
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  z-index: 6;
}
.overview-hero .gradient:before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  opacity: 0.5;
  background: linear-gradient(181.53deg, rgba(232, 203, 192, 0.8) -1.76%, rgba(99, 111, 164, 0.096) 98.7%);
  z-index: 5;
}
.overview-hero:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.95) 85%, rgba(255, 255, 255, 0.3) 100%);
  z-index: 2;
}
.overview-hero .wrapper {
  position: relative;
  z-index: 10;
}
.overview-hero .wrapper .card {
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 1.5rem 1.25rem;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: 52% 40%;
  justify-content: space-between;
  opacity: 1;
}
@media only screen and (max-width: 768px) {
  .overview-hero .wrapper .card {
    grid-template-columns: 100%;
    align-items: flex-start;
    gap: 1.25rem;
  }
}
.overview-hero__left {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0.75rem;
}
@media only screen and (max-width: 768px) {
  .overview-hero__left {
    margin-bottom: 0;
  }
}
.overview-hero__right {
  grid-column: 2;
  grid-row: 1/span 2;
}
@media only screen and (max-width: 768px) {
  .overview-hero__right {
    grid-row: 2;
    grid-column: 1;
  }
}
.overview-hero ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.overview-hero ul.meta-data {
  display: flex;
  color: var(--text-color, #ffffff);
  flex-wrap: wrap;
  margin: 0 !important;
}
.overview-hero ul.meta-data a {
  font-weight: 600;
}
.overview-hero ul.meta-data li {
  color: inherit;
  border-right: 2px solid rgba(0, 0, 0, 0.2);
}
.overview-hero ul.meta-data li:last-child {
  border: 0;
  padding-right: 0;
}
.overview-hero ul.meta-data li:first-child {
  padding-left: 0;
  padding-right: 0.75rem;
}
.overview-hero ul.meta-data li + li {
  margin-left: 0.75rem;
}
.overview-hero ul.meta-data .label {
  font-weight: 600;
  margin-right: 0.25rem;
}
.overview-hero .assignment-type {
  font-size: 0.875rem;
  color: black;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  margin: 0;
  width: -moz-fit-content;
  width: fit-content;
}
.overview-hero .assignment-type:before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #017899;
  margin-right: 0.5rem;
}
.overview-hero h2 {
  font-weight: 400;
  font-size: 2.125rem;
  line-height: 1.3;
  margin-bottom: 0.5rem;
  color: var(--text-color, #ffffff);
}
@media only screen and (max-width: 480px) {
  .overview-hero h2 {
    font-size: 1.5rem;
    font-weight: 400;
  }
}
.overview-hero .action-bar {
  display: flex;
  align-items: flex-end;
  gap: 1.5rem;
  margin-top: 1rem;
}
@media only screen and (max-width: 768px) {
  .overview-hero .action-bar {
    margin-top: 0;
  }
}
@media only screen and (max-width: 480px) {
  .overview-hero .action-bar {
    grid-row: 3;
    gap: 1rem;
  }
}
.overview-hero .action-bar .gr-btn {
  margin: 0;
}
@media only screen and (max-width: 480px) {
  .overview-hero .action-bar .gr-btn {
    display: block;
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  .overview-hero .action-bar {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}

.progress-indicator {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.progress-indicator .progress-info {
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.4;
}
.progress-indicator .progress {
  width: 100%;
  min-width: 18rem;
  height: 9px;
  border-radius: 99px;
  background-color: rgba(0, 0, 0, 0.1);
  position: relative;
}
@media only screen and (max-width: 1200px) {
  .progress-indicator .progress {
    min-width: 12rem;
  }
}
.progress-indicator .progress span {
  display: block;
  left: 0;
  top: 0;
  height: 9px;
  border-radius: 9px;
  background: rgba(0, 0, 0, 0.5);
}

.overall-score {
  border-radius: 0.5rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--text-color, #ffffff);
}
.overall-score h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: var(--text-color, #ffffff);
}
@media only screen and (max-width: 480px) {
  .overall-score h3 {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 480px) {
  .overall-score h3 + span {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1200px) {
  .overall-score {
    padding: 1.5rem;
  }
}
@media only screen and (max-width: 768px) {
  .overall-score {
    background-size: auto, auto;
  }
}
@media only screen and (max-width: 480px) {
  .overall-score {
    height: 8.25rem;
  }
}

.overview-main {
  background: #F5F5F5;
  background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  color: black;
  --text-color:black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  z-index: 0;
}
.overview-main .overview-body {
  position: relative;
  z-index: 2;
}
.overview-main.lesson1, .overview-main.lesson1c {
  background: url("../images/sample_bg2.jpg") no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.overview-main.lesson1:before, .overview-main.lesson1c:before {
  content: "";
  width: 100vw;
  height: 100%;
  background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.overview-main.lesson1:after, .overview-main.lesson1c:after {
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0.8509803922) 100%);
  opacity: 0.4;
  background-size: cover;
  background-position: bottom center;
  position: absolute;
  filter: blur(5px);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}
.overview-main.lesson2 {
  background: url("../images/overview/lesson3-bg.jpg") no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.overview-main.lesson2:before {
  content: "";
  width: 100vw;
  height: 100%;
  background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.overview-main.lesson3, .overview-main.lesson3v1 {
  background: url("../images/overview/lesson2-bg.jpg") no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.overview-main.lesson3:before, .overview-main.lesson3v1:before {
  content: "";
  width: 100vw;
  height: 100%;
  background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.overview-main.lesson3:after, .overview-main.lesson3v1:after {
  width: 100%;
  height: 25vh;
  background: url("../images/overview/lesson3-bg.jpg") no-repeat;
  -webkit-mask-image: linear-gradient(to top, rgb(0, 0, 0) 0%, transparent 100%);
          mask-image: linear-gradient(to top, rgb(0, 0, 0) 0%, transparent 100%);
  opacity: 0.4;
  background-size: cover;
  background-position: bottom center;
  position: absolute;
  filter: blur(5px);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.overview-main.lesson3v2 {
  background: url("../images/overview/lesson3v2-bg.png") no-repeat;
  background-attachment: fixed;
  background-size: 100%;
}
.overview-main.lesson3v2:before {
  width: 100%;
  height: 100%;
  background: url("../images/overview/lesson3-bg-top.png") no-repeat;
  -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(0, 0, 0));
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(0, 0, 0));
  background-size: contain;
  background-attachment: fixed;
  background-position: top center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
}
.overview-main.lesson3v2:after {
  width: 100%;
  height: 100%;
  background: url("../images/overview/lesson3-bg-bottom.png") no-repeat;
  background-size: contain;
  background-position: bottom center;
  background-attachment: fixed;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.overview-main.lesson3v2 .overview-hero .wrapper .card {
  background: transparent;
  padding: 0;
  -webkit-backdrop-filter: none;
          backdrop-filter: none;
}
.overview-main.lesson4 {
  background: url("../images/overview/lesson4-bg.jpg") no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.overview-main.lesson4:before {
  content: "";
  width: 100vw;
  height: 100%;
  background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.overview-main.lesson4:after {
  width: 100%;
  height: 25vh;
  background: url("../images/overview/lesson4-bg.jpg") no-repeat;
  -webkit-mask-image: linear-gradient(to top, rgb(0, 0, 0) 0%, transparent 100%);
          mask-image: linear-gradient(to top, rgb(0, 0, 0) 0%, transparent 100%);
  opacity: 0.4;
  background-size: cover;
  background-position: bottom center;
  position: absolute;
  filter: blur(5px);
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.overview-body {
  padding: 2rem 0;
}
.overview-body .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media only screen and (max-width: 768px) {
  .overview-body .wrapper {
    flex-direction: column-reverse;
    gap: 2rem;
  }
}
.overview-body .overview-body__left {
  flex: 0 0 52%;
}
.overview-body .overview-body__left .assignment-group:first-child {
  margin-top: 0;
}
.overview-body .overview-body__left .assignment-card {
  background: rgb(255, 255, 255);
  margin-bottom: 1px;
  border: 0;
}
.overview-body .overview-body__right {
  flex: 0 0 40%;
}
.overview-body .overview-body__right .card {
  background: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  margin-bottom: 2.5rem;
}
@media only screen and (max-width: 768px) {
  .overview-body .overview-body__right .card {
    margin: 0 0 1.5rem 0 !important;
  }
}

.svg-item {
  position: relative;
  max-width: 8.25rem;
  animation: donutfade 1s;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
}
@media only screen and (max-width: 1200px) {
  .svg-item {
    max-width: 6.25rem;
  }
}
@media only screen and (max-width: 768px) {
  .svg-item {
    max-width: 9.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .svg-item {
    max-width: 6.25rem;
  }
}
@keyframes donutfade {
  /* this applies to the whole svg item wrapper */
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
.svg-item .donut-ring {
  stroke: #fff;
  opacity: 1;
}
@media only screen and (max-width: 480px) {
  .svg-item .donut-ring {
    stroke-width: 0.875rem;
  }
}
.svg-item .donut-segment {
  transform-origin: center;
  animation: donut1 1s;
}
.svg-item .center-text {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1rem;
}
.svg-item .center-text .large {
  font-weight: 400;
  font-size: 2rem;
}
.svg-item .center-text .percent {
  font-size: 1.25rem;
}
@keyframes donut1 {
  0% {
    stroke-dasharray: 0, 100;
  }
  100% {
    stroke-dasharray: 236, 10;
  }
}

.assignment-objectives h4 {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 0.5rem;
}
.assignment-objectives ul {
  margin-left: 1rem;
  padding-left: 0.5rem;
}
@media only screen and (max-width: 768px) {
  .assignment-objectives ul {
    margin-left: 1rem !important;
  }
}
.assignment-objectives ul li {
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.15px;
  margin-bottom: 0.25rem;
}

ul.assignment-summary {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
ul.assignment-summary li {
  padding: 0.5rem;
  text-transform: capitalize;
  letter-spacing: 0.15px;
}
ul.assignment-summary li:last-child {
  border: 0;
}
ul.assignment-summary li:before {
  content: "";
  display: inline-block;
  align-items: center;
  width: 1rem;
  height: 1rem;
  margin-right: 0.75rem;
  border-radius: 50%;
}
ul.assignment-summary li.activities:before {
  background-color: #9348D4;
}
ul.assignment-summary li.readings:before {
  background-color: #5066C5;
}
ul.assignment-summary li.videos:before {
  background: #647A00;
}
ul.assignment-summary li.questions:before {
  background: #753299;
}
ul.assignment-summary li.others::before {
  background: #297F94;
}

.back-to-top {
  background: #7075F7;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  border: 0;
  border-radius: 0.25rem;
  cursor: pointer;
  z-index: 13;
}
@media only screen and (max-width: 480px) {
  .back-to-top {
    bottom: 2rem;
  }
}
.back-to-top:hover {
  background: rgba(0, 127, 163, 0.7);
}

.assignment-card {
  background: #FFFFFF;
  min-height: 5rem;
  padding: 0.75rem 1rem 0.5rem 5rem;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #eee;
}
.assignment-card:focus {
  outline: 2px solid #007FA3;
  outline-offset: -5px;
}
.assignment-card .icon-holder {
  position: absolute;
  left: 1rem;
  top: 1rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assignment-card .icon-holder > svg {
  fill: white;
}
.assignment-card .icon-holder.video {
  background-color: #647A00;
}
.assignment-card .icon-holder.activity {
  background-color: #9348D4;
}
.assignment-card .icon-holder.other {
  background-color: #297F94;
}
.assignment-card .icon-holder.reading {
  background-color: #5066C5;
}
.assignment-card .icon-holder.question {
  background-color: #753299;
}
.assignment-card .item-title {
  font-size: 0.875rem;
  line-height: 1.3;
  letter-spacing: 0.0005em;
  font-weight: 600;
  margin-bottom: 0.5rem;
  line-height: 1.5;
  margin-right: 2.5rem;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.assignment-card .item-meta-data {
  display: flex;
  gap: 0.5rem;
  list-style-type: none;
  align-items: center;
  margin: 0 !important;
  padding: 0;
}
.assignment-card .item-meta-data li {
  padding-left: 0.5rem;
}
.assignment-card .type-chip {
  font-size: 0.75rem;
  font-weight: 600;
  color: #333;
  letter-spacing: 0.14px;
  text-transform: capitalize;
  white-space: nowrap;
  padding-left: 0 !important;
}
.assignment-card .item-time, .assignment-card .item-points {
  color: #333;
  font-weight: 400;
  font-size: 0.75rem;
  text-align: center;
}
.assignment-card .item-time {
  white-space: nowrap;
  border-left: 1px solid rgba(0, 0, 0, 0.23);
}
.assignment-card .item-points {
  border-left: 1px solid rgba(0, 0, 0, 0.23);
  padding-left: 0.5rem;
}
.assignment-card .item-status {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assignment-card .item-status.completed {
  background-color: rgba(3, 130, 56, 0.15);
}
.assignment-card .item-status svg.status-not-started {
  fill: #fff;
}
.assignment-card .item-status svg.status-complete {
  fill: #038238;
}
.assignment-card.in-overview .item-title {
  font-size: 1rem;
}
.assignment-card.in-overview .item-status svg.status-not-started {
  display: none;
}
.assignment-card:hover {
  background-color: rgb(233, 233, 233);
}
.assignment-card:hover .item-title {
  text-decoration: underline;
}
.assignment-card:hover .item-status {
  opacity: 1;
}
.assignment-card[disabled] {
  background-color: #FAFAFA;
}
.assignment-card[disabled] .icon-holder {
  background-color: #888888 !important;
}
.assignment-card[disabled]:hover {
  cursor: default;
  background-color: rgba(250, 250, 250, 0.8784313725);
}
.assignment-card[disabled]:hover .item-title {
  text-decoration: none;
}
.assignment-card[disabled]:focus {
  outline: none;
}
.assignment-card[disabled] .item-status {
  background-color: #f0f0f0;
  opacity: 0.8;
}
.assignment-card[disabled] .item-status svg {
  fill: rgba(0, 0, 0, 0.2);
}
.assignment-card.selected .item-title, .assignment-card.selected .item-meta-data, .assignment-card.selected .item-time, .assignment-card.selected .item-points, .assignment-card.selected .type-chip {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.5);
}
.assignment-card.selected .icon-holder {
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.assignment-card.selected:focus {
  outline-color: #fff;
}
.assignment-card.selected:hover {
  background-color: inherit;
}
.assignment-card.selected.video {
  background-color: #647A00;
}
.assignment-card.selected.video .item-status {
  background-color: rgba(255, 255, 255, 0.25);
}
.assignment-card.selected.video .item-status .status-not-started {
  fill: #7D9900;
}
.assignment-card.selected.video .item-status .status-not-started circle {
  stroke: white;
  stroke-opacity: 1;
}
.assignment-card.selected.video .item-status .status-complete {
  fill: white;
}
.assignment-card.selected.activity {
  background-color: #9348D4;
}
.assignment-card.selected.activity .item-status {
  background-color: rgba(255, 255, 255, 0.25);
}
.assignment-card.selected.activity .item-status .status-not-started {
  fill: #9348D4;
}
.assignment-card.selected.activity .item-status .status-not-started circle {
  stroke: white;
  stroke-opacity: 1;
}
.assignment-card.selected.activity .item-status .status-complete {
  fill: white;
}
.assignment-card.selected.other {
  background-color: #297F94;
}
.assignment-card.selected.other .item-status {
  background-color: rgba(255, 255, 255, 0.25);
}
.assignment-card.selected.other .item-status .status-not-started {
  fill: #297F94;
}
.assignment-card.selected.other .item-status .status-not-started circle {
  stroke: white;
  stroke-opacity: 1;
}
.assignment-card.selected.other .item-status .status-complete {
  fill: white;
}
.assignment-card.selected.reading {
  background-color: #5066C5; /*#566BB7;*/
}
.assignment-card.selected.reading .item-status {
  background-color: rgba(255, 255, 255, 0.25);
}
.assignment-card.selected.reading .item-status .status-not-started {
  fill: #5066C5; /*#566BB7;*/
}
.assignment-card.selected.reading .item-status .status-not-started circle {
  stroke: white;
  stroke-opacity: 1;
}
.assignment-card.selected.reading .item-status .status-complete {
  fill: white;
}
.assignment-card.selected.question {
  background-color: #753299;
}
.assignment-card.selected.question .item-status {
  background-color: rgba(255, 255, 255, 0.25);
}
.assignment-card.selected.question .item-status .status-not-started {
  fill: #7C03B5;
}
.assignment-card.selected.question .item-status .status-not-started circle {
  stroke: white;
  stroke-opacity: 1;
}
.assignment-card.selected.question .item-status .status-complete {
  fill: white;
}

/* Collapsed Nav styles */
.collapsed .assignment-group {
  margin: 0 !important;
  box-shadow: none;
}
.collapsed .assignment-group li:first-child .assignment-card, .collapsed .assignment-group li:last-child .assignment-card {
  border-radius: 0;
}
.collapsed .assignment-group li:first-child .assignment-card .item-status, .collapsed .assignment-group li:last-child .assignment-card .item-status {
  border-radius: 0;
}
.collapsed .assignment-group li .assignment-card {
  border-radius: 0;
  height: 3rem;
  border: 0;
  padding: 0.75rem 0.5rem 0.5rem;
  border-bottom: 1px solid #eee;
  min-height: 0;
}
.collapsed .assignment-group li .assignment-card .item-title, .collapsed .assignment-group li .assignment-card .type-chip, .collapsed .assignment-group li .assignment-card .item-time, .collapsed .assignment-group li .assignment-card .icon-holder, .collapsed .assignment-group li .assignment-card .item-points {
  display: none !important;
}
.collapsed .assignment-group li .assignment-card .item-status {
  grid-column: 1/span 7;
  width: 100%;
}
.collapsed .assignment-group li .assignment-card .item-status.completed {
  background-color: transparent;
}
.collapsed .assignment-group li .assignment-card:hover.video {
  background-color: rgba(100, 122, 0, 0.5019607843);
}
.collapsed .assignment-group li .assignment-card:hover.activity {
  background-color: rgba(147, 72, 212, 0.5019607843);
}
.collapsed .assignment-group li .assignment-card:hover.other {
  background-color: rgba(41, 127, 148, 0.5019607843);
}
.collapsed .assignment-group li .assignment-card:hover.reading {
  background-color: rgba(80, 102, 197, 0.5019607843);
}
.collapsed .assignment-group li .assignment-card:hover.question {
  background-color: rgba(117, 50, 153, 0.5019607843);
}
.collapsed .assignment-group li .assignment-card.selected .item-status.completed {
  background-color: rgb(3, 130, 56);
}

.assignment-group {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  margin: 1rem 0;
  box-shadow: 0px 1px 2px rgba(84, 110, 122, 0.24), 0px 2px 4px rgba(120, 144, 156, 0.24);
}
.assignment-group:first-child {
  margin-top: 0;
}
.assignment-group li:first-child .assignment-card {
  border-radius: 0.5rem 0.5rem 0 0;
}
.assignment-group li:first-child .assignment-card .item-status {
  border-radius: 0 0.5rem 0 0;
}
.assignment-group li:last-child .assignment-card {
  border-radius: 0 0 0.5rem 0.5rem;
}
.assignment-group li:last-child .assignment-card .item-status {
  border-radius: 0 0 0.5rem 0;
}
.assignment-group li:only-child .assignment-card {
  border-radius: 0.5rem;
}

.assignment-group-btn {
  background: rgb(255, 255, 255);
  min-height: 5rem;
  padding: 0.75rem 1rem 0.5rem 1rem;
  cursor: pointer;
  position: relative;
  border: 0;
  border-radius: 0.5rem;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  font-size: 1.125rem;
  text-align: left;
}
.assignment-group-btn .icon svg {
  fill: #546E7A;
  transition: transform 0.15s ease-in-out;
}
.assignment-group-btn .group-title {
  display: block;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 0.25rem;
}
.assignment-group-btn .item-meta-data {
  display: flex;
  gap: 0.5rem;
  list-style-type: none;
  align-items: center;
  margin: 0 !important;
  padding: 0;
}
.assignment-group-btn .item-meta-data li {
  padding-left: 0.5rem;
  font-size: 0.825rem;
}
.assignment-group-btn .item-meta-data li:first-child {
  padding-left: 0;
}
.assignment-group-btn[aria-expanded=true] {
  border-radius: 0.5rem 0.5rem 0 0;
}
.assignment-group-btn[aria-expanded=true] .text {
  font-weight: bold;
}
.assignment-group-btn[aria-expanded=true] .icon svg {
  transform: rotate(180deg);
}

.assignment-group-container {
  margin: 1rem 0;
  filter: drop-shadow(0px 1px 2px rgba(84, 110, 122, 0.24));
}
.assignment-group-container:first-child {
  margin-top: 0;
}
.assignment-group-container .assignment-group {
  margin: 0;
  box-shadow: none;
  animation: 0.1s hide forwards;
}
.assignment-group-container .assignment-group.closed {
  animation: 0.1s hide forwards;
}
.assignment-group-container .assignment-group.open {
  animation: 0.6s hide;
  animation-delay: 0.3s;
}
.assignment-group-container button + .assignment-group.open li:first-child .assignment-card {
  border-radius: 0;
}
.assignment-group-container button + .assignment-group.open li:first-child .assignment-card .item-status {
  border-radius: 0;
}

@keyframes hide {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
    max-height: 0;
    overflow: hidden;
  }
}
.accordion h4 {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #005D83;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 1rem;
  gap: 0.5rem;
}
.accordion h4 svg {
  transform: rotate(0);
  transition: transform linear 0.3s;
}
.accordion .accordion-content {
  margin-bottom: 1rem;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all linear 0.3s;
}
.accordion .accordion-content p {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 1.5;
  letter-spacing: 0.0005em;
  margin-bottom: 0.5rem;
}
.accordion.open h4 svg {
  transform: rotate(90deg);
}
.accordion.open .accordion-content {
  max-height: 50rem;
  opacity: 1;
}

.tippy-box {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: left;
  border-radius: 4px;
  background-color: #546E7A;
  color: #fff;
  padding: 4px 8px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
}
.tippy-box[data-placement^=bottom] > .tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: transparent transparent #546E7A transparent;
  top: -19px;
}
.tippy-box[data-placement^=top] > .tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: #546E7A transparent transparent transparent;
  top: 100%;
}
.tippy-box[data-placement^=left] > .tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: transparent transparent transparent #546E7A;
  top: 50%;
  left: 100%;
}
.tippy-box[data-placement^=right] > .tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px;
  border-color: transparent #546E7A transparent transparent;
  top: 50%;
  right: 100%;
  transform: translateY(50%);
}
.tippy-box.assignment-tip {
  background-color: #F7F8FC;
  margin-top: -3px;
}
.tippy-box.assignment-tip .tippy-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent #f7f8fc transparent;
  top: -22px;
}
.tippy-box.assignment-tip .assignment-tip__content {
  background-color: #F7F8FC;
  color: #1F2933;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  padding: 1rem;
}
.tippy-box.assignment-tip .assignment-tip__content .details-link {
  display: flex;
  justify-content: flex-end;
  font-size: 0.75rem;
}
.tippy-box.assignment-tip .assignment-tip__content > div {
  margin: 0 0 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.player-header {
  background: var(--neutral-white-white-70, rgba(255, 255, 255, 0.7));
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1019607843);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  height: 5rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 1rem 2rem;
  align-items: center;
  color: #000;
  z-index: 30;
  font-family: "TT Commons W05", Arial, Helvetica, sans-serif;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 112;
}
@media only screen and (max-width: 1200px) {
  .player-header {
    padding: 1rem 0.5rem 1rem 1rem;
  }
}
@media only screen and (max-width: 768px) {
  .player-header {
    height: 3rem;
  }
}
.player-header .assignment-type {
  text-transform: capitalize;
}
@media only screen and (max-width: 480px) {
  .player-header .assignment-type .assignment-type__wide {
    display: none;
  }
}
.player-header .assignment-type .assignment-type__mobile {
  display: none;
}
@media only screen and (max-width: 480px) {
  .player-header .assignment-type .assignment-type__mobile {
    display: inline-block;
  }
}
.player-header .assignment-title {
  max-width: 35ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.player-header .progress-indicator {
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.player-header .progress-indicator .progress-info {
  white-space: nowrap;
}
.player-header .progress-indicator .progress {
  height: 9px;
  background: rgb(231, 231, 231);
  padding: 0;
}
.player-header .progress-indicator .progress span {
  left: 0;
  top: 0;
  height: 100%;
  transition: width 0.3s ease-in;
  background: linear-gradient(86.65deg, #1CE6B5 -81.59%, #7075F7 100%);
}
.player-header .gr-btn.icon-btn-18 svg, .player-header .gr-btn.icon-btn-24 svg {
  fill: #000;
}
.player-header .gr-btn.btn-close {
  margin-left: 1rem;
  padding: 8px 20px;
  background-color: #03313E;
  border: 0;
  color: #fff;
  font-size: 1rem;
}
@media only screen and (max-width: 480px) {
  .player-header .gr-btn.btn-close {
    margin: 0;
  }
}
.player-header .gr-btn.btn-close .mobile-view {
  display: none;
}
@media only screen and (max-width: 480px) {
  .player-header .gr-btn.btn-close .mobile-view {
    display: inline;
  }
}
.player-header .gr-btn.btn-close .desktop-view {
  display: inline;
}
@media only screen and (max-width: 480px) {
  .player-header .gr-btn.btn-close .desktop-view {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .player-header .gr-btn.btn-close {
    padding: 0.5rem;
    background: transparent;
  }
}
.player-header .player-header__left {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__left #info-icon {
    display: none;
  }
}
.player-header .player-header__left .hamburger {
  display: none;
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background: transparent;
  border: none;
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__left .hamburger {
    display: block;
  }
}
.player-header .player-header__left .hamburger > span {
  display: block;
  width: 1.5rem;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: #151515;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
.player-header .player-header__left .hamburger > span:first-child {
  transform-origin: 0% 0%;
}
.player-header .player-header__left .hamburger > span:nth-last-child(2) {
  transform-origin: 0% 100%;
}
.player-header .player-header__left .hamburger.open > span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  transform-origin: 50% 50%;
  margin: 0;
}
.player-header .player-header__left .hamburger.open > span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}
.player-header .player-header__left .hamburger.open > span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
  transform-origin: 50% 50%;
}
.player-header .player-header__center {
  text-align: center;
  transition: left linear 0.3s;
}
@media only screen and (max-width: 1200px) {
  .player-header .player-header__center {
    left: 42%;
  }
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__center {
    display: none;
  }
}
.player-header .player-header__center .item-type {
  text-transform: capitalize;
  font-size: 1.25rem;
  font-weight: 600;
}
.player-header .player-header__center .step-info {
  font-weight: 400;
  font-size: 0.875rem;
}
.player-header .player-header__right {
  display: flex;
  align-items: center;
  gap: 8px;
}
.player-header .player-header__right .mobile-score-btn {
  display: none;
  margin: 0;
}
.player-header .player-header__right .mobile-score-btn svg {
  fill: none !important;
  stroke: white;
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__right .mobile-score-btn {
    display: block;
  }
  .player-header .player-header__right .mobile-score-btn:hover {
    background-color: transparent;
  }
  .player-header .player-header__right .mobile-score-btn:active, .player-header .player-header__right .mobile-score-btn:focus {
    outline: 0;
    border: 0;
  }
}
.player-header .player-header__right .score-info > span {
  display: inline-block;
  margin: 0 0.25rem;
}
@media only screen and (max-width: 1200px) {
  .player-header .player-header__right .score-info > span {
    margin: 0 0.125rem;
  }
}
.player-header .player-header__right .score-info > span.label {
  font-weight: 600;
}
.player-header .player-header__right .score-info > span.inline-val {
  font-weight: 300;
  white-space: nowrap;
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__right .score-info > span.divider {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .player-header .player-header__right .score-info > span {
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 768px) {
  .player-header .player-header__right .score-info {
    display: none;
    min-width: 225px;
    position: absolute;
    top: calc(100% + 0.125rem);
    right: 7.25rem;
    color: #333;
    background: #fff;
    border: 1px solid #ddd;
    padding: 0.5rem 0.75rem;
    gap: 0.5rem;
    border-radius: 0 0 0.25rem 0.25rem;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);
  }
  .player-header .player-header__right .score-info:before, .player-header .player-header__right .score-info:after {
    bottom: 99%;
    right: 12px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .player-header .player-header__right .score-info:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: #ffffff;
    border-width: 10px;
    margin-right: 0px;
  }
  .player-header .player-header__right .score-info:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: #c7c7c7;
    border-width: 12px;
    margin-right: -2px;
  }
  .player-header .player-header__right .score-info.open {
    display: grid;
    grid-template-columns: 50% auto;
  }
}
@media only screen and (max-width: 480px) {
  .player-header .player-header__right .score-info {
    right: 3.25rem;
  }
}

.player-main {
  display: flex;
  position: relative;
  background: #FFF;
}
.player-main aside {
  max-width: 375px;
  width: 100%;
  transition: all ease-in-out 0.5s;
  position: fixed;
  z-index: 115;
  margin-top: 70px;
}
.player-main aside .aside-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 70px);
}
.player-main aside .scroll-btn {
  display: none;
}
.player-main aside nav {
  overflow: auto;
  flex: 1;
  padding: 0 1rem;
  scroll-behavior: smooth;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
@media only screen and (max-width: 768px) {
  .player-main aside nav {
    padding-top: 1.25rem;
  }
}
@media only screen and (max-width: 480px) {
  .player-main aside nav {
    padding-top: 0;
  }
}
.player-main aside nav::-webkit-scrollbar {
  width: 0.25rem;
}
.player-main aside nav::-webkit-scrollbar-track {
  background: #f8f8f8;
}
.player-main aside nav::-webkit-scrollbar-thumb {
  background: #c7c7c7;
}
.player-main aside nav::-webkit-scrollbar-thumb:hover {
  background: #999;
}
.player-main aside .nav-top {
  flex: 0 0 2.5rem;
  width: 100%;
  text-align: center;
}
.player-main aside .nav-top #closeBtn {
  display: none;
}
@media only screen and (max-width: 768px) {
  .player-main aside .nav-top {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .player-main aside .nav-top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    background: transparent;
    border: 0;
  }
  .player-main aside .nav-top #minimizeBtn {
    display: none;
  }
  .player-main aside .nav-top #closeBtn {
    display: block;
    border: 0;
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem;
  }
  .player-main aside .nav-top #closeBtn svg {
    margin: 0;
  }
}
.player-main aside:not(.collapsed) {
  background: rgba(207, 207, 207, 0.3);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  height: 100%;
}
.player-main aside:not(.collapsed) #minimizeBtn {
  width: 2.5rem;
  height: 2.5rem;
  background: white;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 50%;
  transform: translateY(-50%);
  margin: 0;
}
.player-main aside.collapsed {
  background: transparent;
  max-width: 7.125rem;
  border: 0;
  padding: 2.5rem 2rem 3rem 2rem;
  box-shadow: none;
  /* Styles for assignment card are in _assignmentcard.scss */
}
.player-main aside.collapsed .aside-inner {
  max-height: calc(100vh - 70px - 5.5rem);
  transition: max-height 0.3s ease;
  transition-delay: 0.15s;
}
.player-main aside.collapsed .progress-indicator {
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
}
.player-main aside.collapsed .scroll-btn {
  display: block;
  border-bottom: 1px solid #eee !important;
  border-radius: 0;
}
.player-main aside.collapsed .scroll-btn:last-child {
  border: 0;
  border-top: 1px solid #eee;
  border-radius: 0 0 0.5rem 0.5rem;
}
.player-main aside.collapsed .aside-inner {
  border: 0.5px solid #B6B6B6;
  box-shadow: 0px 3px 19px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fff;
}
.player-main aside.collapsed nav {
  overflow: hidden;
  padding: 0;
  overflow-y: auto;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.player-main aside.collapsed nav::-webkit-scrollbar {
  width: 0.0625rem;
}
.player-main aside.collapsed nav::-webkit-scrollbar-track {
  background: #fff;
}
.player-main aside.collapsed nav::-webkit-scrollbar-thumb {
  background: #fff;
}
.player-main aside.collapsed nav::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
.player-main aside.collapsed .nav-top {
  padding: 0.25rem;
  gap: 0;
  background-color: transparent;
  margin: 0;
  border-bottom: 0.5px solid rgba(51, 51, 51, 0.2509803922);
}
.player-main aside.collapsed .nav-top .gr-btn {
  color: #666666;
  margin: 0;
  width: 100%;
  min-height: 40px;
}
.player-main aside.collapsed .nav-top .gr-btn span {
  display: none;
}
.player-main aside.collapsed .nav-top .gr-btn svg {
  transform: rotate(180deg);
}
@media only screen and (max-width: 768px) {
  .player-main aside.closed {
    height: 0;
    overflow: hidden;
    top: -10px;
  }
  .player-main aside.closed .aside-inner {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .player-main aside {
    position: absolute;
    left: 0;
    z-index: 20;
  }
}
@media only screen and (max-width: 480px) {
  .player-main aside {
    position: absolute;
    left: 0;
    top: -3rem;
    z-index: 50;
    width: 100%;
    max-width: none;
    background: rgba(0, 0, 0, 0.7);
    height: calc(100vh - 4rem);
  }
}
.player-main article {
  flex: 1;
}
.player-main article .item-content {
  position: relative;
  z-index: 5;
}
.player-main article .item-content > h3 {
  font-weight: 600;
  max-width: 46.25rem;
  margin: 2rem auto 0;
}
.player-main article .player-footer {
  border-radius: 100px;
  background: var(--text-theme-dark, #FFF);
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 36px;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 10;
  padding: 0.5rem;
  transition: left ease-in-out 0.5s;
  min-width: 122px;
}
.player-main article .player-footer .gr-btn.icon-btn-24 {
  width: 2.25rem;
  height: 2.25rem;
}
@media only screen and (max-width: 480px) {
  .player-main article .player-footer {
    padding: 0.75rem 1rem;
  }
}
.player-main article .player-footer.full-width {
  left: 0;
}
.player-main article .player-footer__left {
  display: flex;
  align-items: center;
}
.player-main article .player-footer__right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.player-main.hide-header aside.collapsed {
  margin-top: 0;
}
.player-main.hide-header aside.collapsed .aside-inner {
  max-height: calc(100vh - 5.5rem);
}

#playerBody .footer {
  display: none;
}

.content-part {
  max-width: 49.25rem;
  margin: 2rem auto;
  padding: 5rem 1.5rem 0 1.5rem;
}
.content-part.full-width, .content-part.iframe {
  max-width: none;
  position: relative;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}
.content-part.full-width iframe, .content-part.iframe iframe {
  border: 0;
  overflow-x: hidden;
}
.content-part.applet {
  padding: 80px;
}
.content-part.applet iframe {
  max-width: 1100px;
  min-height: calc(100vh - 160px);
  margin: 0 auto;
  display: block;
}
.content-part h3 {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 2rem !important;
}
.content-part p {
  margin-bottom: 1rem;
}

.video-player {
  width: 100%;
  height: auto;
  border: 1px solid #c7c7c7;
}

iframe {
  width: 100%;
  height: 100%;
}

.question-stem {
  margin-bottom: 1rem;
}

.math-large {
  font-size: 2.25rem;
  line-height: 1rem;
}

.answer-options {
  list-style-type: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
.answer-options li {
  margin: 0 !important;
  padding: 0 !important;
}
.answer-options li:marker {
  display: none;
}

.answer {
  margin-bottom: 1.5rem;
}
.answer .small-input {
  width: 3rem;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  font-size: 0.875rem;
  padding: 0.25rem;
}
.answer .inline-select {
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  display: inline-block;
  font-size: 0.875rem;
  padding: 0.25rem;
}
.answer .small {
  font-size: 0.875rem;
}

.answer-status {
  background-color: #f5faf5;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.07);
}
.answer-status .title {
  color: #038238;
  align-items: center;
  display: flex;
  font-size: 1rem;
  font-weight: 600;
  justify-content: space-between;
  padding: 0.75rem 1rem;
}

/* Player footer actions that switch to dropdown in mobile view */
.mobile-dd-trigger {
  display: none;
}
@media only screen and (max-width: 480px) {
  .mobile-dd-trigger {
    display: inline-flex;
    gap: 0.5rem;
  }
}

.item-actions {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 480px) {
  .item-actions {
    display: none;
    flex-direction: column;
    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    padding: 0.25rem 1rem;
  }
  .item-actions.open {
    display: flex;
    position: absolute;
    bottom: 3.25rem;
    left: 1rem;
  }
}
@media only screen and (max-width: 480px) {
  .item-actions .gr-btn {
    display: block;
    width: 100%;
    text-align: left;
  }
}

.Resizer {
  z-index: 1;
  box-sizing: border-box;
  position: relative;
}
.Resizer:before {
  content: "\e5d3";
  font-family: "Material Icons";
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
  height: 0.5rem;
  width: 3rem;
  border-radius: 0.5rem;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Resizer:hover {
  transition: all 2s ease;
}
.Resizer.horizontal {
  height: 1px;
  background-color: #C2C7CC;
  cursor: row-resize;
  margin: 0.5rem 0;
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.SplitPane.horizontal {
  position: static !important;
}

.Pane.horizontal {
  padding: 20px;
  overflow: auto;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.Pane.horizontal::-webkit-scrollbar {
  width: 6px;
}
.Pane.horizontal::-webkit-scrollbar-track {
  background: transparent;
}
.Pane.horizontal::-webkit-scrollbar-thumb {
  background: #737373;
  border-radius: 4px;
}
.Pane.horizontal::-webkit-scrollbar-thumb:hover {
  background: #a3a5a7;
}
.Pane.Pane1 {
  flex: 1 1 60%;
}
.Pane.Pane2 {
  flex: 1 1 40%;
}
.Pane.Pane2 img {
  max-height: 400px;
}

/* Switch/Toggle */
.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #a9aca8 url(../images/icon_cross.png) no-repeat 28px center;
  transition: 0.4s;
  border-radius: 34px;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  top: 3px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch input:checked + .slider {
  background: #5066c5 url(../images/icon_check.png) no-repeat 9px center;
}
.switch input:checked + .slider:before {
  transform: translateX(24px);
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #047A9C;
}
.switch input:disabled + .slider, .switch input[disabled] + .slider {
  background-color: #C2C7CC;
}

.etext {
  display: block;
  width: 100vw;
  height: 100vh;
  background: #DDDEDF;
  position: relative;
}
.etext .etext__navbar {
  position: absolute;
  left: 0;
  width: 5.875rem;
  height: 100vh;
  background: #F7F9FD;
  box-shadow: 0px 1px 4px rgba(28, 34, 46, 0.05), 0px 2px 12px rgba(28, 34, 46, 0.06);
  border-radius: 0px 16px 16px 0px;
  padding: 2rem 1.5rem;
  z-index: 10;
}
.etext .etext__navbar > ul {
  margin: 2rem 0 0 0;
  list-style-type: none;
}
.etext .etext__navbar > ul li {
  margin-bottom: 1rem;
}
.etext .etext__navbar .btn-close {
  bottom: 2rem;
  left: 1.5rem;
  position: absolute;
}
.etext .etext__header {
  position: fixed;
  top: 0;
  height: 4rem;
  width: 100vw;
  z-index: 5;
  /*grid-column: 2;
  grid-row: 1; */
  background: linear-gradient(180deg, #F6F9FD 78.12%, rgba(247, 249, 253, 0) 100%);
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 1.5rem;
}
.etext .etext__header h1 {
  font-family: "TT Commons W05", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.etext .etext__header .user-id {
  background: #F6F9FD;
  border: 1px solid #ECF1FB;
  border-radius: 20px;
  width: 2.5rem;
  height: 2.5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.etext .etext__body {
  height: 100vh;
  padding-left: 5.875rem;
  overflow: auto;
}
.etext .etext__body .page {
  max-width: 64rem;
  background: #fff;
  margin: 0 auto;
  padding: 5rem 5rem 6rem;
}
.etext .etext__footer {
  /*grid-column: 2;
  grid-row: 3;*/
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 5.5rem;
  background: linear-gradient(180deg, #F6F9FD 38.64%, rgba(221, 222, 223, 0) 100%);
  transform: rotate(180deg);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 0.5rem;
}
.etext .etext__footer img {
  transform: rotate(180deg);
}

.bronte-content {
  --color-primary: #232323;
  --color-secondary: rgba(26, 26, 26, 0.80); /*#1a1a1a;*/
  --color-tertiary: rgba(26, 26, 26, 0.65);
  --color-disabled: rgba(0, 0, 0, 0.50);
  --gradient-1-strong: linear-gradient(73deg, #4858D4 0.7%, #009184 116.15%);
  --gradient-1-light:linear-gradient(74deg, #C6CDFF 0.66%, #DEF1F1 99.66%);
  --gradient-2-strong: linear-gradient(74deg, #207C9E 0.66%, #262699 99.66%);
  --gradient-2-light:linear-gradient(74deg, #CDF3FF 0.66%, #E9EBFF 99.66%);
  --gradient-3-light:linear-gradient(51deg, #CDF3FF 23.98%, #7D8DE9 142.8%);
  --gradient-4-light:linear-gradient(0deg, #CDF3FF -91.25%, #E9EBFF 100%);
  --Neutral-Normal-Content-Primary: #232323;
  --Neutral-Normal-Content-Tertiary: #1A1A1AA6;
  --Neutral-Normal-Background-Primary: #FFFFFF;
  --Neutral-Inverse-Content-Primary: #FFFFFF;
  --Neutral-Inverse-Content-Tertiary: #FFFFFFA6;
  --Neutral-Inverse-Background-Primary: #212121;
  --Theme-Accent-1-Lightest: #E9EBFF;
  --Theme-Accent-1-Lighter: #C6CDFF;
  --Theme-Accent-1-Light: #7D8DE9;
  --Theme-Accent-1-Base: #4858D4;
  --Theme-Accent-1-Dark: #3944BC;
  --Theme-Accent-1-Darker: #262699;
  --Theme-Accent-1-Darkest: #191966;
  --Theme-Accent-2-Lightest: #DEF1F1;
  --Theme-Accent-2-Lighter: #ACDEDA;
  --Theme-Accent-2-Light: #74C8C2;
  --Theme-Accent-2-Base: #009184;
  --Theme-Accent-2-Dark: #007567;
  --Theme-Accent-2-Darker: #00493B;
  --Theme-Accent-2-Darkest: #003329;
  --Theme-Accent-3-Lightest: #CDF3FF;
  --Theme-Accent-3-Lighter: #A8DCF3;
  --Theme-Accent-3-Light: #64A5C3;
  --Theme-Accent-3-Base: #207C9E;
  --Theme-Accent-3-Dark: #005A76;
  --Theme-Accent-3-Darker: #003248;
  --Theme-Accent-3-Darkest: #002333;
  --Neutral-Normal-Background-Primary: #FFFFFF;
  --Neutral-Inverse-Content-Primary: #FFFFFF;
  --Neutral-Inverse-Content-Tertiary: #FFFFFFA6;
  --System-Informative-Lightest: #F1F7FE;
  --System-Informative-Lighter: #E3EEF7;
  --System-Informative-Light: #9ACDEF;
  --System-Informative-Base: #0075B1;
  --System-Informative-Dark: #00639B;
  --System-Informative-Darker: #003F65;
  --System-Informative-Darkest: #041E2D;
  --System-Positive-Lightest: #EEF6F6;
  --System-Positive-Lighter: #E0F0F0;
  --System-Positive-Light: #9AC1C1;
  --System-Positive-Base: #318181;
  --System-Positive-Dark: #006161;
  --System-Positive-Darker: #024040;
  --System-Positive-Darkest: #0B1E1E;
  --System-Attention-Lightest: #FDFBE7;
  --System-Attention-Lighter: #F5F2DB;
  --System-Attention-Light: #D2BA98;
  --System-Attention-Base: #8D7041;
  --System-Attention-Dark: #6A5329;
  --System-Attention-Darker: #47371A;
  --System-Attention-Darkest: #21190D;
  --System-Negative-Lightest: #FFF6F5;
  --System-Negative-Lighter: #FBEBE9;
  --System-Negative-Light: #FFA39E;
  --System-Negative-Base: #BF4044;
  --System-Negative-Dark: #9F2D34;
  --System-Negative-Darker: #691C20;
  --System-Negative-Darkest: #311211;
  font-family: "Poppins", sans-serif;
  font-size: 100%;
  margin: 0;
  padding: 0;
}

.global-footer {
  margin: 0;
  background: #F5F5F5;
}
.global-footer .wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  line-height: 2;
  gap: 1rem;
  padding: 0.5rem 0;
}
@media only screen and (max-width: 768px) {
  .global-footer .wrapper {
    flex-direction: column;
    padding: 0.5rem 1rem;
  }
}
.global-footer p {
  margin: 0;
}
.global-footer a {
  color: #444;
}

.body-wrap {
  display: flex;
  min-height: 100vh;
  background-image: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(137.13deg, #EFEFEF 0%, #4CA8C3 100%);
  background-repeat: no-repeat, no-repeat;
  background-position: center;
  background-size: cover, cover;
  background-attachment: fixed;
  overflow-x: hidden;
  font-family: Inter, Helvetica, sans-serif;
}
.body-wrap .main-nav {
  flex: 0 0 6.75rem;
}
.body-wrap main {
  flex: 1;
  mix-blend-mode: normal;
  min-width: calc(100vw - 6.75rem);
}
.body-wrap main .app-header {
  background: transparent;
}
.body-wrap main .wrapper {
  max-width: 64rem;
  margin: 2rem auto;
}
.body-wrap main .wrapper h2 {
  margin: 1rem 0;
  font-weight: bold;
  font-size: 1.5rem;
}
.body-wrap .section-header {
  background: linear-gradient(114.06deg, rgba(0, 0, 0, 0.3490196078) 0%, rgba(0, 0, 0, 0.1490196078) 100%), linear-gradient(159.55deg, #34b9dc 0%, #5c71cd 100%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px 8px 0 0;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.body-wrap .section-header .search-assignments {
  display: flex;
  align-items: center;
  color: white;
  gap: 0.5rem;
}
.body-wrap .section-header .search-assignments select {
  background-color: white;
  border-radius: 22px;
  padding: 0.5rem 2rem 0.5rem 1rem;
  min-width: 15rem;
  margin-right: 1rem;
  color: #020917;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;
}
.body-wrap .section-header .actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.body-wrap .section-header .actions .MuiButton-root {
  border: 2px solid white;
  color: white;
}
.body-wrap .section-header .actions .MuiToggleButton-root {
  border: 0;
  border-radius: 50% !important;
}
.body-wrap .section-header .actions .MuiToggleButton-root.Mui-selected {
  background-color: rgba(254, 254, 254, 0.56);
  color: #293e98;
}
.body-wrap .main-content {
  margin: 2rem 8rem;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
}
.sidebar .header-logo {
  margin-bottom: 3rem;
}
.sidebar .main-menu {
  list-style: none;
  margin: 0px;
  padding: 8px 0px;
  position: relative;
}
.sidebar .main-menu .menu-item {
  padding: 0;
  border-radius: 50%;
  max-height: 3rem;
  margin: 1.5rem 0;
}
.sidebar .main-menu .menu-item a {
  display: block;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  text-indent: -9999px;
  background-color: rgba(2, 9, 23, 0.32);
}
.sidebar .main-menu .menu-item.selected a {
  background-color: #5bdda0;
}

.assignment-metadata {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.assignment-row {
  cursor: pointer;
}
.assignment-row:hover {
  background: rgba(254, 254, 254, 0.8);
}
.assignment-row .metadata {
  margin: 0;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: rgba(0, 0, 0, 0.6);
  display: block;
}
.assignment-row .assignment-title {
  margin: 0px 0px 8px;
  color: rgb(2, 9, 23);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
  line-height: 1.43;
  text-transform: none;
}
.assignment-row .assignment-title:hover {
  text-decoration: underline;
}

span.difficulty-label {
  color: #585858;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1; /* 100% */
  letter-spacing: 0.15px;
  display: block;
}

.table-wrapper {
  color: rgb(2, 9, 23);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(254, 254, 254, 0.9) 30%, rgba(254, 254, 254, 0.3) 90%);
}

.ov-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-family: Inter, Helvetica, sans-serif;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  border-radius: 2rem;
  border: 2px solid rgb(2, 9, 23);
  color: rgb(2, 9, 23);
  padding: 10px 24px;
  font-size: 14px;
  text-decoration: none;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.4px;
  text-transform: none;
  line-height: 1.42857;
}
.ov-button.outlined {
  border: 2px solid #fff;
  color: #fff;
}

.toggle-button-group .toggle-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-decoration: none;
  font-family: Inter, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.75;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 11px;
  border: 0;
  border-radius: 50% !important;
  color: rgba(254, 254, 254, 0.96);
}
.toggle-button-group .toggle-button.selected {
  background-color: rgba(254, 254, 254, 0.5607843137);
  color: #293e98;
}/*# sourceMappingURL=styles.css.map */