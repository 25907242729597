.overview-main {
    background: #F5F5F5;
    // background: linear-gradient(0deg, rgba(205, 243, 255, 0.00) -91.25%, #DFEEFF 28.74%, #E9EBFF 74.8%, rgba(233, 235, 255, 0.00) 130%), url('../images/sample_bg2.jpg') no-repeat;
    // backdrop-filter: blur(10px);
    // background:linear-gradient(0deg, rgba(217, 217, 217, 0.00) 0%, #d9d9d9d9 100%) no-repeat;
    background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
    backdrop-filter: blur(50px);
        color:black;
    --text-color:black;
    display:flex;
    flex-direction:column;
    min-height:100vh;
    background-attachment: fixed;
    background-size: cover;
    position: relative;
    z-index: 0;
    // overflow: hidden;
    .overview-body {
        // flex:1;
        // overflow:auto;
        position: relative;
        z-index: 2;
    }
    &.lesson1, &.lesson1c {
        // background: #F5F5F5;
        background:url('../images/sample_bg2.jpg') no-repeat;
        background-attachment: fixed;
        background-size: cover;
        &:before {
            content:'';
            width:100vw;
            // height:15vh;
            height:100%;
            // background:url('../images/overview/lesson1-bg-top.png') no-repeat;
            background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
            // background: linear-gradient(113deg, #4858D4 0.7%, rgba(0, 145, 132, 0.40) 116.15%);
            background-size:cover;
            // background-position: bottom center;
            background-attachment: fixed;
            position:fixed;
            // backdrop-filter: blur(70px);
            // top:30px;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index: 0;
        }
        &:after {
            // content:'';
            width:100%;
            height:100vh;
            background:linear-gradient(0deg, rgba(217, 217, 217, 0.00) 0%, #d9d9d9d9 100%);
            // background:url('../images/overview/lesson1-bg-bottom.png') no-repeat;
            // mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 0%, transparent 100%);
            opacity:0.4;
            background-size:cover;
            background-position: bottom center;
            position:absolute;
            filter: blur(5px);
            bottom:0;
            left:0;
            right:0;
            top:0;
            z-index: 1;
        }  
    }
    &.lesson2 {
        // background:linear-gradient(0deg, rgba(217, 217, 217, 0.00) 0%, #d9d9d9d9 100%),url('../images/overview/lesson2-bg.jpg') no-repeat;
        background:url('../images/overview/lesson3-bg.jpg') no-repeat;
        background-attachment: fixed;
        background-size: cover;
        // background: #F5F5F5;
        &:before {
            content:'';
            width:100vw;
            // height:15vh;
            height:100%;
            
            // background:linear-gradient(90deg, rgba(217, 217, 217, 0.00) -50%, #d9d9d9d9 50%, rgba(217, 217, 217, 0.00) 150%);
            // background:url('../images/overview/lesson1-bg-top.png') no-repeat;
            background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
            background-size:cover;
            // background-position: bottom center;
            background-attachment: fixed;
            position:fixed;
            // backdrop-filter: blur(70px);
            // top:30px;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index: 0;
        }
      
    }
    
    &.lesson3, &.lesson3v1 {
        background:url('../images/overview/lesson2-bg.jpg') no-repeat;
        background-attachment: fixed;
    background-size: cover;
        // background: #F5F5F5;
        &:before {
            content:'';
            width:100vw;
            // height:15vh;
            height:100%;
            // background:url('../images/overview/lesson3-bg-top.png') no-repeat;
            background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
            background-size:cover;
            // background-position: bottom center;
            background-attachment: fixed;
            position:fixed;
            // backdrop-filter: blur(70px);
            // top:30px;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index: 0;
        }
        &:after {
            // content:'';
            width:100%;
            height:25vh;
            background:url('../images/overview/lesson3-bg.jpg') no-repeat;
            mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 0%, transparent 100%);
            opacity:0.4;
            background-size:cover;
            background-position: bottom center;
            position:absolute;
            filter: blur(5px);
            bottom:0;
            left:0;
            right:0;
            z-index: 1;
        }  
    }
    &.lesson3v2 {
        // background: linear-gradient(51deg, #CDF3FF66 23.98%, #7D8DE966 142.8%);
        background:url('../images/overview/lesson3v2-bg.png') no-repeat;
        background-attachment: fixed;
    background-size: 100%;
        &:before {
            // content:'';
            width:100%;
            height:100%;
            background:url('../images/overview/lesson3-bg-top.png') no-repeat;
            -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            background-size:contain;
            background-attachment: fixed;
            background-position: top center;
            position:absolute;
            // opacity:0.4;
            top:0;
            right:0;
            left:0;
            z-index: 0;
        }
        &:after {
            // content:'';
            width:100%;
            height:100%;
            background:url('../images/overview/lesson3-bg-bottom.png') no-repeat;
            background-size:contain;
            background-position: bottom center;
            background-attachment: fixed;
            position:absolute;
            bottom:0;
            right:0;
            left:0;
            z-index: 1;
        }

        .overview-hero .wrapper .card {
            background:transparent;
            padding:0;
            backdrop-filter: none;
        }
    }
    &.lesson4 {
        background:url('../images/overview/lesson4-bg.jpg') no-repeat;
        background-attachment: fixed;
    background-size: cover;
        // background: #F5F5F5;
        &:before {
            content:'';
            width:100vw;
            // height:15vh;
            height:100%;
            // background:url('../images/overview/lesson1-bg-top.png') no-repeat;
            // background: linear-gradient(0deg, rgba(217, 217, 217, 0.00) 0%, #d9d9d9d9 100%);
            background: linear-gradient(170deg, rgba(91, 169, 226, 0.47) 19.73%, rgba(134, 144, 242, 0.37) 73.12%);
            background-size:cover;
            // background-position: bottom center;
            background-attachment: fixed;
            position:fixed;
            // backdrop-filter: blur(70px);
            // top:30px;
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index: 0;
        }
        &:after {
            // content:'';
            width:100%;
            height:25vh;
            background:url('../images/overview/lesson4-bg.jpg') no-repeat;
            mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 0%, transparent 100%);
            opacity:0.4;
            background-size:cover;
            background-position: bottom center;
            position:absolute;
            filter: blur(5px);
            bottom:0;
            left:0;
            right:0;
            z-index: 1;
        }  
    }
}
.overview-body {
    padding: 2rem 0;
    // background-color: #F5F5F5;
    // background:linear-gradient(to top, #f5f5f5, transparent);

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include devices(medium) {
            flex-direction: column-reverse;
            gap: 2rem;
        }
        
    }

    .overview-body__left {
        flex: 0 0 52%;

        .assignment-group:first-child {
            margin-top:0;
        }
        .assignment-card {
            background:rgba(255,255,255,1);
            margin-bottom:1px;
            border:0;
        }
    }

    .overview-body__right {
        flex: 0 0 40%;
        

        .card {
            // background: linear-gradient(261.73deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.5) 100%);
            // background:rgba(255,255,255,0.8);
            background:rgba(255,255,255,0.8);
            -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
            padding: 1rem 1.25rem;
            border-radius:0.5rem;
            margin-bottom:2.5rem;

            @include devices(medium) {
                margin:  0 0 1.5rem 0 !important;
            }
        }
    }    
}

.svg-item {
    position: relative;
    max-width: 8.25rem;
    animation: donutfade 1s;
    // filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
    filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
    @include devices(large) {
        max-width: 6.25rem;
    }
    
    @include devices(medium) {
        max-width: 9.25rem;
    }
    
    @include devices(small) {
        max-width: 6.25rem;
    }


    @keyframes donutfade {
        /* this applies to the whole svg item wrapper */
        0% {
            opacity: .2;
        }
        100% {
            opacity: 1;
        }
    }

    .donut-ring {
        stroke: #fff;
        // stroke: rgb(231, 231, 231);
        opacity:1;
        // stroke-width: 1rem;

        @include devices(small) {
            stroke-width: 0.875rem;
        }
    }
    
    .donut-segment {
        transform-origin: center;
        animation: donut1 1s;  
        // stroke: #007FA3;
        // stroke: #7075F7;
        
    }

    .center-text {
        position: absolute;
        top: 48%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height:1rem;

        .large {
            font-weight: 400;
            font-size: 2rem;
        }
        .percent {
            font-size:1.25rem;
        }
    }
    
    @keyframes donut1 {
        0% {
            stroke-dasharray: 0, 100;
        }
        100% {
            stroke-dasharray: 236, 10;
        }
    }
}

.assignment-objectives {
    h4 {
        font-size: 1.125rem;
        font-weight: 600;     
        line-height: 1.5;   
        margin-bottom: 0.5rem;
    }

    ul {
        margin-left: 1rem;
        padding-left: 0.5rem;

        @include devices(medium) {
            margin-left: 1rem !important;
        }

        li {
            line-height: 1.5;
            font-weight: 400;
            letter-spacing: 0.15px;
            margin-bottom: 0.25rem;
        }
    }
}

ul.assignment-summary {
    list-style-type: none;
    margin: 0;
    padding: 0;

  

    li {
        padding: 0.5rem;
        text-transform: capitalize;
        letter-spacing: 0.15px;

        &:last-child {
            border: 0;
        }

        &:before {
            content: "";
            display: inline-block;
            align-items: center;
            width: 1rem;
            height: 1rem;
            margin-right: 0.75rem;
            border-radius: 50%;
        }

        &.activities {
            &:before {
                // background-color: #C45303;
                background-color: #9348D4;
            }
        }

        &.readings {
            &:before {
                // background: #566BB7;
                background-color:#5066C5;
            }
        }
        &.videos {
            &:before {
                background: #647A00;
            }
        }
        &.questions {
            &:before {
                background: #753299;
            }
        }
        &.others {
            &::before {
                background: #297F94;
            }
        }
    }
}

.back-to-top {
    background: #7075F7;
    // background: rgba(0, 127, 163, 1);
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    border: 0;
    border-radius:0.25rem;
    cursor: pointer;
    z-index: 13;

    @include devices(small) {
        bottom: 2rem;
    }

    &:hover {
        background: rgba(0, 127, 163, 0.7);
    }
}